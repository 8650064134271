<script>
import {
  toLocaleDateTime,
  numToLocaleString,
  phoneRegex,
  addressPreparation
} from '@/helpers/functions'
import { accreditationStatuses } from './constants'

export default {
  name: 'TableCell',
  props: {
    data: {
      type: [String, Array, Number, Boolean],
      default: ''
    },
    row: {
      type: Object,
      default: () => {}
    },
    propKey: {
      type: String,
      default: ''
    },
    status: {
      type: String,
      default: ''
    },
    counter: {
      type: Object,
      default: () => ({})
    },
    is_unlimited: {
      type: Boolean,
      default: false
    }
  },
  render: function (createElement) {
    let classNames = this.propKey
    let renderData = [this.data]

    if (Array.isArray(this.data)) {
      renderData = this.data.map(item => createElement('div', item))
    }

    switch (this.propKey) {
      case 'status':
        renderData = ''
        classNames = [this.propKey, `bg-${this.status}`]
        break
      case 'comment':
        renderData = accreditationStatuses[this.status]
        classNames = [this.status]
        break
      case 'created_at':
      case 'utime_start':
        renderData = toLocaleDateTime(renderData[0], 'shortWithHours')
        break
      case 'phone':
        renderData = phoneRegex(this.data)
        break
      case 'expires_at':
        renderData = this.is_unlimited
          ? 'Бессрочный'
          : toLocaleDateTime(renderData[0], 'short')
        break
      case 'contract_expire_date':
        renderData = toLocaleDateTime(renderData[0], 'short')
        break
      case 'contract_date':
        renderData = toLocaleDateTime(renderData[0], 'short', false)
        if (renderData === 'Invalid Date') renderData = '-'
        break
      case 'status_left': {
        const span = createElement('div', {
          class: `status bg-${this.counter?.status}`
        })
        renderData = [span, this.isTablet ? '' : this.counter?.counter]
        classNames = [this.propKey, 'df ai-c jc-c']
        break
      }
      case 'result': {
        const {
          counter_my_price_is_winning: winner,
          counter_best_price: best,
          counter_my_price: my
        } = this.row

        let status = ''
        let span = ''
        let text = 'Не ставил'
        if (this.isBidder) {
          if (my) {
            status = winner ? 'accepted' : 'declined'
            text = winner ? 'Победитель' : 'Проигравший'
            if (best < my && winner) status = 'golden'
            span = createElement('div', { class: `status bg-${status} mr-20` })
          }
        } else {
          text = best ? 'успешно' : 'не разыгран'
          status = best ? 'accepted' : 'declined'
          span = createElement('div', { class: `status bg-${status} mr-20` })
        }
        renderData = [span, this.isTablet ? '' : text]
        classNames = [this.propKey, 'df ai-c']
        break
      }
      case 'direction': {
        const {
          load_region,
          load_district,
          load_city,
          unload_region,
          unload_district,
          unload_city,
          distance
        } = this.row
        renderData = [
          createElement('div', { class: 'fw-b' }, [
            addressPreparation(load_region, load_district, load_city)
          ]),
          createElement('div', [
            addressPreparation(unload_region, unload_district, unload_city)
          ])
        ]
        if (distance) {
          renderData.push(`Расстояние: ${distance} км`)
        }
        break
      }
      case 'consignee_address': {
        const { region, city, district, address } = this.row
        renderData = addressPreparation(region, district, city, address)
        break
      }
      case 'prices': {
        const { price_start, counter_best_price, counter_my_price } = this.row
        const prices = [price_start, counter_best_price]
        if (this.isBidder) prices.push(counter_my_price)
        renderData = prices.map(item =>
          createElement('div', numToLocaleString(item))
        )
        break
      }
      case 'counter_my_price': {
        const {
          counter_best_price,
          counter_my_price,
          counter_my_price_is_winning
        } = this.row

        if (!counter_my_price) renderData = 'не ставил'
        else if (!counter_my_price_is_winning) classNames += ' declined'
        else if (counter_best_price < counter_my_price) classNames += ' golden'
        else classNames += ' accepted'

        break
      }
      case 'bidder_org': {
        const { bidder_org_name, bidder_org_inn } = this.row
        renderData = [
          createElement('div', bidder_org_name),
          createElement('div', bidder_org_inn)
        ]
      }
    }

    return createElement('div', { class: classNames }, renderData)
  }
}
</script>

<style lang="sass">
@import "~@/assets/styles/mixins.sass"
.status
  width: 19px
  height: 19px
  border-radius: 50%
  margin: 0 auto
  &.mr-20
    margin-right: 20px !important
    margin-left: 0
  @include to(1024px)
    width: 11px
    height: 11px
.status_left
  .status
    margin: 0 25px 0 0
    @include to(1024px)
      margin: 0
.el-icon-info
  font-size: 20px
</style>
