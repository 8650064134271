<template>
  <el-table
    v-loading="loading"
    :data="participants"
    style="width: 100%"
    empty-text="Нет данных"
    stripe
    :class="['bidders-groups-table mb-20 of-hidden', { secondary }]"
    :height="isMobile ? '300px' : height"
    :row-key="getRowKeys"
    @selection-change="handleSelectionChange"
  >
    <el-table-column
      v-if="selection"
      key="selection"
      type="selection"
      width="55"
    />
    <el-table-column
      v-for="el in tableFields"
      :key="el.prop"
      :label="el.label"
      :prop="el.prop"
      :width="el.width + 'px'"
      :min-width="el.minWidth ? `${el.minWidth}px` : ''"
    >
      <template slot="header">
        {{ el.label }}
        <TableCell :data="el.span" />
      </template>
      <template slot-scope="props">
        <TableCell :data="props.row[el.prop]" :prop-key="el.prop" />
      </template>
    </el-table-column>
  </el-table>
</template>

<script>
import TableCell from '@/components/Ui/TableCell'
import { getGroupTableFields } from './constants'

export default {
  name: 'BiddersGroupTable',
  components: { TableCell },
  props: {
    participants: {
      type: Array,
      default: () => []
    },
    loading: {
      type: Boolean,
      default: false
    },
    secondary: {
      type: Boolean,
      default: false
    },
    selection: {
      type: Boolean,
      default: true
    },
    height: {
      type: String,
      default: 'calc(100% - 140px)'
    }
  },
  computed: {
    tableFields() {
      if (this.isMobile) return getGroupTableFields('Mobile')
      else return getGroupTableFields()
    }
  },
  methods: {
    getRowKeys(row) {
      return row.id
    },
    handleSelectionChange(val) {
      this.$emit('change', val)
    }
  }
}
</script>

<style lang="sass">
.bidders-groups-table
  background: transparent
  &:before
    background: transparent
</style>
