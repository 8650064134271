const validationSelect = { required: true, message: ' ', trigger: 'change' }

const validationTextInput = { required: true, message: ' ', trigger: 'blur' }

const validationNumberInput = {
  type: 'number',
  required: true,
  message: ' ',
  trigger: 'blur'
}

export const rules = {
  load_address: validationTextInput,
  unload_address: validationTextInput,
  product_id: validationSelect,
  load_weight_total: validationNumberInput,
  load_weight_daily: validationNumberInput,
  load_type_id: validationSelect,
  utime_load_start: validationTextInput,
  utime_load_stop: validationTextInput,
  price_start: validationNumberInput,
  utime_start: validationTextInput,
  utime_stop: validationTextInput
}

export const trtFields = [
  { prop: 'user', label: 'Участник' },
  { prop: 'price', label: 'Ставка', width: 150 },
  { prop: 'weight', label: 'Вес ставки', width: 150 }
]

export const trtVendorFields = [
  { prop: 'user', label: 'Участник' },
  { prop: 'namePhone', label: 'Имя Ф./Телефон' },
  { prop: 'orgInfo', label: 'Огранизация' },
  { prop: 'price', label: 'Ставка', width: 150 },
  { prop: 'weight', label: 'Вес ставки', width: 150 }
]

export const filterParticipantsOptions = [
  { id: 1, label: 'Были приглашены', value: 0 },
  { id: 2, label: 'Не приглашены', value: 1 },
  { id: 3, label: 'Были приглашены в группе', value: 2 },
  { id: 4, label: 'Не приглашены в группе', value: 3 }
]

export const tenderMakeRateRules = {
  price: {
    required: true,
    message: 'Введите цену',
    trigger: 'blur'
  },
  weight: {
    required: true,
    message: 'Введите вес ставки',
    trigger: 'blur'
  }
}

export const addTenderConfig = {
  load: [
    {
      gutter: 40,
      cols: [
        { span: 12, xs: 24, prop: 'load_region' },
        { span: 12, xs: 24, prop: 'load_city' }
      ]
    },
    {
      gutter: 40,
      cols: [{ span: 12, xs: 24, prop: 'load_address' }]
    }
  ],
  unload: [
    {
      gutter: 40,
      cols: [
        { span: 12, xs: 24, prop: 'unload_region' },
        { span: 12, xs: 24, prop: 'unload_city' }
      ]
    },
    {
      gutter: 40,
      cols: [{ span: 12, xs: 24, prop: 'unload_address' }]
    }
  ],
  consignee: [
    {
      gutter: 40,
      cols: [
        {
          span: 12,
          xs: 24,
          label: 'Грузополучатель',
          prop: 'consignee_id',
          className: 'responsive-column',
          link: 'Нет в списке? добавить.'
        },
        {
          span: 12,
          xs: 24,
          prop: 'distance',
          label: 'Расстояние, км'
        }
      ]
    }
  ],
  production: [
    {
      gutter: 40,
      cols: [
        {
          span: 11,
          xs: 24,
          prop: 'product_id',
          label: 'Наименование',
          link: 'Нет в списке? добавить.'
        },
        {
          span: 4,
          xs: 12,
          className: 'responsive-column',
          prop: 'load_weight_total',
          label: 'Объем, т'
        },
        {
          span: 9,
          xs: 12,
          className: 'responsive-column',
          prop: 'load_weight_daily',
          label: 'Суточный объем, т/сут'
        }
      ]
    },
    {
      gutter: 40,
      cols: [
        {
          span: 11,
          xs: 24,
          prop: 'load_type_id',
          label: 'Способ погрузки'
        }
      ]
    }
  ],
  conditions: [
    {
      gutter: 50,
      cols: [
        {
          span: 8,
          xs: 12,
          className: 'responsive-column',
          prop: 'utime_load_start',
          label: 'Начало погрузки'
        },
        {
          span: 8,
          xs: 12,
          className: 'responsive-column',
          prop: 'utime_load_stop',
          label: 'Окончание погрузки'
        }
      ]
    },
    {
      gutter: 50,
      cols: [
        {
          span: 8,
          xs: 24,
          prop: 'price_start',
          label: 'Начальная цена, руб/тонн'
        }
      ]
    }
  ],
  descr: [{ gutter: 50, cols: [{ span: 24, xs: 24, prop: 'comment' }] }],
  participant: [
    {
      gutter: 50,
      cols: [
        {
          span: 8,
          xs: 12,
          className: 'responsive-column',
          prop: 'utime_start',
          label: 'Начало тендера, дата'
        },
        {
          span: 8,
          xs: 12,
          className: 'responsive-column',
          prop: 'utime_stop',
          label: 'Завершение тендера, время',
          link: '1 час',
          link2: '30 мин'
        }
      ]
    }
  ],
  otvetstvenniy: [
    {
      gutter: 50,
      cols: [
        {
          span: 8,
          xs: 12,
          className: 'responsive-column',
          prop: 'otvetstvenniy_phone',
          label: 'Телефон'
        },
        {
          span: 8,
          xs: 12,
          className: 'responsive-column',
          prop: 'otvetstvenniy_name',
          label: 'ФИО'
        }
      ]
    }
  ]
}

export const config = {
  0: {
    left: { text: 'Автоторги', command: 'goNext', input: 'Моя цена, руб' },
    right: {
      text: 'Сделать ставку',
      command: 'createOffer',
      input: 'Объем перевозки, т'
    }
  },
  1: {
    left: {
      text: 'К ставкам',
      command: 'goBack',
      input: 'Торговаться до цены'
    },
    right: {
      text: 'Начать автоторги',
      command: 'startAutoTrading',
      input: 'Какой объем'
    }
  },
  2: { left: { text: 'Отменить автоторги', command: 'goBack' } }
}

export const getTableFields = (archive, isBidder = false, type = '') => {
  const fields = func[`ttFields${archive ? 'Archive' : ''}${type}`](isBidder)
  if (!archive && isBidder && !type) {
    fields.splice(-1, 0, {
      prop: 'counter_my_price',
      label: 'ЦЕНА',
      span: 'Ваша',
      width: 90,
      loading: true
    })
  }
  return fields
}

const func = {
  ttFields: () => [
    { prop: 'utime_start', label: 'ДАТА НАЧАЛА', width: 120 },
    {
      prop: 'status_left',
      label: 'СТАТУС, осталось',
      span: 'до окончания/начала',
      minWidth: 160,
      loading: true
    },
    { prop: 'direction', label: 'НАПРАВЛЕНИЕ', minWidth: 260 },
    { prop: 'el-icon-info', label: '', minWidth: 35 },
    { prop: 'product_name', label: 'КУЛЬТУРА', width: 100 },
    { prop: 'load_weight_total', label: 'ОБЪЕМ', width: 80 },
    { prop: 'price_start', label: 'ЦЕНА', span: 'старт', width: 80 },
    {
      prop: 'counter_best_price',
      label: 'ЦЕНА',
      span: 'текущая',
      width: 80,
      loading: true
    },
    {
      prop: 'counter_bidders_count',
      label: 'Количество',
      span: 'участников',
      width: 100,
      loading: true
    }
  ],
  ttFieldsSmallDesktop: isBidder => [
    { prop: 'utime_start', label: 'ДАТА НАЧАЛА', width: 120 },
    {
      prop: 'status_left',
      label: 'СТАТУС, осталось',
      span: 'до окончания/начала',
      width: 160,
      loading: true
    },
    { prop: 'direction', label: 'НАПРАВЛЕНИЕ', minWidth: 250 },
    { prop: 'el-icon-info', label: '', minWidth: 35 },
    { prop: 'product_name', label: 'КУЛЬТУРА', width: 100 },
    { prop: 'load_weight_total', label: 'ОБЪЕМ', width: 80 },
    {
      prop: 'prices',
      label: 'ЦЕНА',
      span: isBidder ? ['старт', 'текущая', 'ваша'] : ['старт', 'текущая'],
      width: 80,
      loading: true
    },
    {
      prop: 'counter_bidders_count',
      label: 'Количество',
      span: 'участников',
      width: 100,
      loading: true
    }
  ],
  ttFieldsTablet: isBidder => [
    { prop: 'status_left', label: '', width: 31, loading: true },
    { prop: 'utime_start', label: 'ДАТА', width: 70 },
    { prop: 'direction', label: 'НАПРАВЛЕНИЕ', minWidth: 200 },
    { prop: 'el-icon-info', label: '', minWidth: 35 },
    { prop: 'product_name', label: 'КУЛЬТУРА', width: 100 },
    { prop: 'load_weight_total', label: 'ОБЪЕМ', width: 80 },
    {
      prop: 'prices',
      label: 'ЦЕНА',
      span: isBidder ? ['старт', 'текущая', 'ваша'] : ['старт', 'текущая'],
      width: 90,
      loading: true
    },
    {
      prop: 'counter_bidders_count',
      label: 'Количество',
      span: 'участников',
      width: 100,
      loading: true
    }
  ],
  ttFieldsMobile: isBidder => [
    { prop: 'status_left', label: '', width: 31, loading: true },
    { prop: 'utime_start', label: 'ДАТА', width: 70 },
    { prop: 'direction', label: 'НАПРАВЛЕНИЕ' },
    { prop: 'el-icon-info', label: '', minWidth: 35 },
    {
      prop: 'prices',
      label: 'ЦЕНА',
      span: isBidder ? ['старт', 'текущая', 'ваша'] : ['старт', 'текущая'],
      width: 70,
      loading: true
    }
  ],
  ttFieldsArchive: isBidder => [
    { prop: 'utime_start', label: 'ДАТА НАЧАЛА', width: 120 },
    { prop: 'result', label: 'РЕЗУЛЬТАТ', width: 160 },
    { prop: 'direction', label: 'НАПРАВЛЕНИЕ', minWidth: 260 },
    { prop: 'el-icon-info', label: '', minWidth: 35 },
    { prop: 'product_name', label: 'КУЛЬТУРА', width: 100 },
    {
      prop: 'load_weight_total',
      label: 'ОБЪЕМ К',
      span: 'ИСПОЛНЕНИБЮ, т',
      width: 150
    },
    isBidder
      ? { prop: 'counter_my_price', label: 'ЦЕНА', span: 'Ваша', width: 90 }
      : { prop: 'price_winning', label: 'ЦЕНА', span: 'лучшая', width: 80 },
    {
      prop: 'counter_bidders_count',
      label: 'Количество',
      span: 'участников',
      width: 100,
      loading: true
    }
  ],
  ttFieldsArchiveSmallDesktop: isBidder => [
    { prop: 'utime_start', label: 'ДАТА НАЧАЛА', width: 120 },
    { prop: 'result', label: 'РЕЗУЛЬТАТ', width: 160 },
    { prop: 'direction', label: 'НАПРАВЛЕНИЕ', minWidth: 250 },
    { prop: 'el-icon-info', label: '', minWidth: 35 },
    { prop: 'product_name', label: 'КУЛЬТУРА', width: 100 },
    { prop: 'load_weight_total', label: 'ОБЪЕМ', width: 80 },
    isBidder
      ? { prop: 'counter_my_price', label: 'ЦЕНА', span: 'Ваша', width: 90 }
      : { prop: 'price_winning', label: 'ЦЕНА', span: 'лучшая', width: 80 },
    {
      prop: 'counter_bidders_count',
      label: 'Количество',
      span: 'участников',
      width: 100,
      loading: true
    }
  ],
  ttFieldsArchiveTablet: isBidder => [
    { prop: 'result', label: '', width: 31, loading: true },
    { prop: 'utime_start', label: 'ДАТА', width: 70 },
    { prop: 'direction', label: 'НАПРАВЛЕНИЕ', minWidth: 200 },
    { prop: 'el-icon-info', label: '', minWidth: 35 },
    { prop: 'product_name', label: 'КУЛЬТУРА', width: 100 },
    { prop: 'load_weight_total', label: 'ОБЪЕМ', width: 80 },
    isBidder
      ? { prop: 'counter_my_price', label: 'ЦЕНА', span: 'Ваша', width: 90 }
      : { prop: 'price_winning', label: 'ЦЕНА', span: 'лучшая', width: 80 },
    {
      prop: 'counter_bidders_count',
      label: 'Количество',
      span: 'участников',
      width: 100,
      loading: true
    }
  ],
  ttFieldsArchiveMobile: isBidder => [
    { prop: 'result', label: '', width: 31, loading: true },
    { prop: 'utime_start', label: 'ДАТА', width: 70 },
    { prop: 'direction', label: 'НАПРАВЛЕНИЕ' },
    { prop: 'el-icon-info', label: '', minWidth: 35 },
    isBidder
      ? { prop: 'counter_my_price', label: 'ЦЕНА', span: 'Ваша', width: 90 }
      : { prop: 'price_winning', label: 'ЦЕНА', span: 'лучшая', width: 80 }
  ]
}
