<template>
  <el-table
    v-loading="loading"
    :data="participants"
    style="width: 100%"
    empty-text="Нет данных"
    stripe
    :class="['bidders-table mb-20 of-hidden', { secondary }]"
    :height="isMobile ? '300px' : height"
    :row-key="getRowKeys"
    @selection-change="handleSelectionChange"
  >
    <el-table-column v-if="selection" type="selection" width="55" />
    <el-table-column property="contract" label="Номер договора" width="100" />
    <el-table-column label="Имя Ф./Телефон" width="175" class-name="phone">
      <template slot-scope="scope">
        <div>{{ `${scope.row.first_name} ${scope.row.last_name}.` }}</div>
        <input
          class="tamt-login ta-c"
          v-mask="'+7 (###) ###-##-##'"
          :value="scope.row.login"
          masked="true"
          readonly
        />
      </template>
    </el-table-column>
    <el-table-column label="Организация" width="175">
      <template v-if="scope.row.organization" slot-scope="scope">
        <div>{{ scope.row.organization.name }}</div>
        <div>{{ scope.row.organization.inn }}</div>
      </template>
    </el-table-column>
    <el-table-column label="E-mail">
      <template v-if="scope.row.email" slot-scope="scope">
        <div>
          <el-tooltip
            effect="dark"
            :content="scope.row.email"
            placement="bottom-end"
          >
            <div>{{ scope.row.email }}</div>
          </el-tooltip>
        </div>
      </template>
    </el-table-column>
    <el-table-column property="userIsLock" width="50">
      <template slot-scope="scope">
        <div v-if="scope.row.userIsLock">
          <img
            class="add-tender-table__img"
            src="@/assets/imgs/icons/lock.svg"
            alt="icon"
          />
        </div>
      </template>
    </el-table-column>
  </el-table>
</template>

<script>
export default {
  name: 'BiddersTable',
  props: {
    participants: {
      type: Array,
      default: () => []
    },
    loading: {
      type: Boolean,
      default: false
    },
    secondary: {
      type: Boolean,
      default: false
    },
    selection: {
      type: Boolean,
      default: true
    },
    height: {
      type: String,
      default: 'calc(100% - 140px)'
    }
  },
  methods: {
    getRowKeys(row) {
      return row.id
    },
    handleSelectionChange(val) {
      this.$emit('change', val)
    }
  }
}
</script>

<style lang="sass">
@import "~@/assets/styles/mixins.sass"

.bidders-table
  background: transparent
  min-height: 130px
  &:before
    background: transparent
  .el-tooltip
    text-overflow: ellipsis
    overflow: hidden
  .cell, input
    font-size: 15px
    line-height: 18px
    word-break: normal
    &:focus
      outline: none
  .phone .cell
    text-overflow: unset
</style>
